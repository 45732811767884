import React, { FunctionComponent } from 'react'
import { MediaSelectorImageType } from 'Root/Shared/components/content/mediaImage/PuxPicture'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxGallery from '../PuxGallery/PuxGallery'
import { PuxGalleryItemType } from '../PuxGallery/PuxGalleryItem'

export interface PuxGallerySetType {
  contentType: 'PuxGallerySet'
  gallerySetItems: {
    mediaSelectorImage: MediaSelectorImageType
    mediaSelectorTitle: {
      html: string
    }
  }
}

export interface PuxGalleryWrapperType {
  contentItemId?: string
  displayText: string
  galleryWrapperItemsToLightbox: boolean
  galleryWrapperItemsSet: {
    contentItems: PuxGallerySetType[]
  }
}

export interface WidgetPuxGalleryWrapperType
  extends WidgetBase,
  PuxGalleryWrapperType {
  contentType: 'PuxGalleryWrapper'
}

const WidgetPuxGalleryWrapper: FunctionComponent<WidgetPuxGalleryWrapperType> = (
  props
) => {
  const inputData =
    props.galleryWrapperItemsSet?.contentItems[0]?.gallerySetItems
  const text = inputData?.mediaSelectorTitle?.html
  const imageData = inputData?.mediaSelectorImage

  const contentItems: PuxGalleryItemType[] = imageData?.resizePaths?.map(
    (mainPath, idx) => {
      return {
        alt: text,
        galleryItemImage: {
          resizePaths: [mainPath],
          resizePathsTablet: [imageData.resizePathsTablet[idx]],
          resizePathsMobile: [imageData.resizePathsMobile[idx]],
          texts: text,
        },
      }
    }
  )

  return (
    <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
      <PuxGallery
        displayText={props.displayText}
        galleryList={{ contentItems: contentItems }}
        galleryItemsToLightbox={props.galleryWrapperItemsToLightbox}
      />
    </WidgetContainer>
  )
}

export default WidgetPuxGalleryWrapper
